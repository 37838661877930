import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MensajeService } from 'src/app/service/mensaje.service';
import { TokenService } from 'src/app/service/token.service';

@Component({
  selector: 'app-mensajes',
  templateUrl: './mensajes.component.html',
  styleUrls: ['./mensajes.component.scss']
})
export class MensajesComponent implements OnInit {
  seccion = "Mensajes";
  messages = [];
  comprobacionTabla = false;
  isPermision = false;
  roles: string;
  fecha;
  constructor(private tokenService: TokenService, private mensajesService: MensajeService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.roles = this.tokenService.getAuthorities();

    if (this.roles == 'Administrador' || this.roles == 'SuperAdministrador') {
      this.isPermision = true;

    } else {
      this.isPermision = false;
    }

    this.mensajesService.getMenssage().subscribe((respuesta) => {
      this.messages = respuesta;
      if (this.messages.length < 1) {

        this.comprobacionTabla = false;
        $("#tabla").css('display', 'none');

      } else {
        this.comprobacionTabla = true;
      }
    
      var i = 0
      respuesta.forEach(element => {
        var indice = element.created.indexOf("T")
        this.fecha = element.created.substring(0, indice);
        var fecha1 = new Date(this.fecha);
        var dia = fecha1.getDate()+1
        var mes = fecha1.getMonth()+1
        var anio = fecha1.getFullYear()
        var fechaCompleta = dia+"/"+mes+"/"+anio
        this.messages[i].fecha = fechaCompleta;
        i++;
      });

    });
  }

}
