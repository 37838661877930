
export class Novedad {
    nombre:string;
    pdf:string;
  constructor(
    nombre:string,
    pdf:string
    ){
      this.nombre = nombre, this.pdf=pdf
      
    }


}
