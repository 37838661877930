import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/models/usuario';
import {TokenService} from 'src/app/service/token.service';
import { ToastrService } from 'ngx-toastr';
import { UsuarioService } from 'src/app/service/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {
  isPermision = false;
  roles :string;
  usuario: Usuario[] = [];
  p: number = 1;
  collection: any[] = [];
  comprobacionTabla = false;
  seccion = "usuarios";
  filterPost = '';
  constructor(private tokenService : TokenService, private usuarioService: UsuarioService, private toastr: ToastrService, private router: Router) {
      
   }

  ngOnInit(): void {
    this.roles = this.tokenService.getAuthorities();
  
    if(this.roles == 'Administrador' || this.roles == 'SuperAdministrador'){
      this.isPermision = true;
    } else {
      this.router.navigate(['/dashboard']);
    }

    this.usuarioService.getUsuarios();
    this.usuarioService.get().subscribe((respuesta) => {
      this.usuario = respuesta;
      if(this.usuario.length<1){
      
        this.comprobacionTabla = false;
        $("#tabla").css('display','none'); 
        
      } else{
        this.comprobacionTabla = true;
      }
    });
   
  }

  eliminarUsuario(i, data) {
    
    Swal.fire({
      title: '¿Está seguro que  desea eliminar este usuario?',  
      icon: 'warning',
      cancelButtonColor: "#929292", 
      confirmButtonColor: "#DD6B55", 
      showCancelButton: true,
      confirmButtonText: 'Eliminar',  
      cancelButtonText: 'Cancelar'
    }).
    then((result) => {
      if (result.isConfirmed) {
        this.usuarioService.eliminarUsuario(data._id).subscribe((respuesta) => {  
          this.usuario.splice(i,1);
        });
        this.toastr.success('Eliminado con exito', 'Correcto', {timeOut: 2000, progressBar: true, closeButton: true})  
        if(this.usuario.length<=1){
          this.comprobacionTabla = false;
          $("#tabla").css('display','none'); 
        }
      }
    })
    
  }


}
