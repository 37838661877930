import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class NovedadService {

  constructor(private _http : HttpClient, private _url : UrlService) { }

  guardarNovedad(novedad: any): Observable<any> {
    return this._http.post(this._url.getURLBase() + '/novedad/new_novedad',novedad );
  }

  get(): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/novedad/');
  }

  eliminarPDF(novedad_id : any ): Observable<any> {
    return this._http.delete(this._url.getURLBase() + '/novedad/' + novedad_id);
  }
}
