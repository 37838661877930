import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_BASE_HREF } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { PagesModule } from "./pages/pages.module";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoginComponent } from "./pages/dashboard/login/login.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { MenuComponent } from "./pages/dashboard/menu/menu.component";
import { FooterComponent } from "./pages/dashboard/footer/footer.component";
import { HeaderComponent } from "./pages/dashboard/header/header.component";
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { UrlService } from './service/url.service';
import { ProdGuardService } from './guards/prod-guard.service';
import { ProdInterceptorService } from './interceptors/prod-interceptor.service';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from "primeng/button";
import { NovedadesComponent } from "./pages/dashboard/novedades/novedades.component";
import { NuevaNovedadComponent } from "./pages/dashboard/novedades/nueva-novedad/nueva-novedad.component";
import { ToastrModule } from 'ngx-toastr';
import { UsuariosComponent } from "./pages/dashboard/usuarios/usuarios.component";
import { NuevoUsuarioComponent } from "./pages/dashboard/usuarios/nuevo-usuario/nuevo-usuario.component";
import { MensajesComponent } from "./pages/dashboard/mensajes/mensajes.component";
import { ListadosComponent } from "./pages/dashboard/listados/listados.component";
import { NuevoListadoComponent } from "./pages/dashboard/listados/nuevo-listado/nuevo-listado.component";
import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module
import { FilterPipeModule } from 'ngx-filter-pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

// Firebase + environment
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

import { environment } from '../environments/environment';

import {MatProgressBarModule} from '@angular/material/progress-bar'

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    MenuComponent,
    FooterComponent,
    HeaderComponent,
    NovedadesComponent,
    NuevaNovedadComponent,
    UsuariosComponent,
    NuevoUsuarioComponent,
    MensajesComponent,
    ListadosComponent,
    NuevoListadoComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    MatInputModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    PagesModule,
    FontAwesomeModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    LottieModule.forRoot({ player: playerFactory }),
    SidebarModule,
    ButtonModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    MatProgressBarModule,
    NgxPaginationModule,
    FilterPipeModule,
    Ng2SearchPipeModule
  ],

  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    UrlService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProdInterceptorService,
      multi: true,
    },
    ProdGuardService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
