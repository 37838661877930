import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';
@Injectable({
  providedIn: 'root'
})
export class MensajeService {

  constructor(private _http: HttpClient, private _url: UrlService) { }

  enviar(mensaje): Observable<any> {
    return this._http.post(this._url.getURLBase() + "/mensaje/new_mensaje", mensaje);
  }

  getMenssage(): Observable<any> {
    return this._http.get(this._url.getURLBase() + "/mensaje");
  }
}
