import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NovedadService } from 'src/app/service/novedad.service';
import { TokenService } from 'src/app/service/token.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-novedades',
  templateUrl: './novedades.component.html',
  styleUrls: ['./novedades.component.scss']
})
export class NovedadesComponent implements OnInit {
  isAdmin = false;
  novedades:any[] =[];
  comprobacionTabla = false;
  fecha;
  constructor(private tokenService : TokenService, private novedadesService : NovedadService, private _toastr: ToastrService,) { }

  ngOnInit(): void {
     if (this.tokenService.getAuthorities() == "Administrador"){
       this.isAdmin = true;
     }
     this.novedadesService.get().subscribe((respuesta) => {
      this.novedades = respuesta;
      if(this.novedades.length == 0){
        this.comprobacionTabla = false
      } else {
        this.comprobacionTabla = true
      }
      var i = 0
      respuesta.forEach(element => {
        var indice = element.created.indexOf("T")
        this.fecha = element.created.substring(0, indice);
        var fecha1 = new Date(this.fecha);

        this.novedades[i].fecha = fecha1;
        i++;
      });
    });
  }

  downloadPdf(base64String, fileName){
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }
  onClickDownloadPdf(data){
    this.downloadPdf(data.pdf,data.nombre);
  }

  eliminarNovedad(i,data) {
    Swal.fire({
      title: '¿Está seguro que  desea eliminar este PDF?',  
      icon: 'warning',
      cancelButtonColor: "#929292", 
      confirmButtonColor: "#DD6B55", 
      showCancelButton: true,
      confirmButtonText: 'Eliminar',  
      cancelButtonText: 'Cancelar'
    }).
    then((result) => {
      if (result.isConfirmed) {
        this.novedadesService.eliminarPDF(data._id).subscribe((respuesta) => {  
          this.novedades.splice(i,1);
          
        });

        this._toastr.success('Eliminado con exito', 'Correcto', {timeOut: 2000, progressBar: true, closeButton: true})       
      }
    })
    
  }
}
