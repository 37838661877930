import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';
import { MensajeService } from './mensaje.service';
@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(private _http: HttpClient, private _url: UrlService, private mensajeService: MensajeService) { }

  enviar(mensaje): Observable<any> {
    return this.mensajeService.enviar(mensaje);
  }
}
