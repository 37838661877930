import { Component, OnInit } from '@angular/core';
import{UrlService} from 'src/app/service/url.service'
import { Router, ActivatedRoute} from '@angular/router';
import {LoginUsuario} from 'src/app/models/login-usuario';
import {TokenService} from 'src/app/service/token.service';
import {LoginService} from 'src/app/service/login.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isLogged = false;
  isLoginFail = false;
  loginUsuario: LoginUsuario;

  username: string;
  password: string;
  flag_confinicial: String
  roles: string[] =[];
  errMsj: string;
  configuracionInicial;
  id;
  flag: boolean = false;
  hide = true;
  rol : string;
  
  constructor(
    private tokenService : TokenService,
    private urlService: UrlService,
    private loginService : LoginService,
    private router: Router,
    private _activate : ActivatedRoute
  ) {

  }

  ngOnInit() {
    if(this.tokenService.getToken()){
      this.router.navigate(['/dashboard']);
    }
    this.isLogged = true;
    this.isLoginFail = false
    //this.roles = this.tokenService.getAuthorities();

    $(document).ready(function() {
      setTimeout(function() {
        $(".page-content").fadeIn(1500);},500);

      setTimeout(function() {
          $(".cssload-dots").fadeOut(1500);},500);

    });

  }

  onLogin(): void {
    this.loginUsuario = new LoginUsuario(this.username, this.password);
    this.loginService.login(this.loginUsuario).subscribe(
      data => {
        if(data.success ){   
          this.isLogged = true;
          this.isLoginFail = false;
          this.tokenService.setToken(data.token);
          this.tokenService.setUserName(data.username);
          this.rol = data.authorities.authority;
          this.tokenService.setAuthorities(data.authorities.authority.nombre);
          //this.tokenService.setName(data.nombre);
          this.tokenService.setId(data.id);
          this.roles = data.authorities;
          this.router.navigate(['/dashboard']);
        }else{
          this.isLogged = false;
          this.isLoginFail = true;
          this.router.navigate(['/admin']);

          Swal.fire({
            title: 'Usuario o Contraseña incorrecta',
            icon: 'warning',
            confirmButtonColor: "#F70C0C",
            confirmButtonText: 'Continuar',
          })
        }

      }
    )

  }

}
