import { Component, OnInit } from '@angular/core';
import { Comuna } from 'src/app/models/comuna';
import { Provincia } from 'src/app/models/provincia';
import { Usuario } from 'src/app/models/usuario';
import { Rol } from 'src/app/service/roles.service'
import { Toast, ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/service/usuario.service';
import { TokenService } from 'src/app/service/token.service';

@Component({
  selector: 'app-nuevo-usuario',
  templateUrl: './nuevo-usuario.component.html',
  styleUrls: ['./nuevo-usuario.component.css']
})
export class NuevoUsuarioComponent implements OnInit {
  usuario : Usuario;
  //provincias : Provincia[] = [];
  //localidades : Comuna[] = [];
  
  nombre: string;
  apellido: string;
  email: string;
  empresa: string;
  provincia: Array<any> = [];
  comuna: Array<any>;
  provinciaSeleccionada;
 comunaSeleccionada;
  localidad: number;
  telefono: number;
  username: string;
  password: string;
  rol: any = {};
  activo: string;
  isPermision = false;
  roles: string;

  constructor(
    private usuarioService: UsuarioService,
    private _toastr: ToastrService,
    private router: Router,
    private tokenService: TokenService
  ) { }

  ngOnInit(): void {
    this.roles = this.tokenService.getAuthorities();

    if (this.roles == 'Administrador' || this.roles == 'SuperAdministrador') {
      this.isPermision = true;

    } else {
      this.isPermision = false;
    }
 
  }
  onSave(data){
    
    if (!data.valid) {
      this.nombre = data.nombre;
      this.apellido = data.apellido;
      this.email = data.email;
      this.telefono = data.telefono;
      this.username = data.username;
      this.password = data.password;
      this.empresa = data.empresa;
      this.rol = {"_id":"6216ea42e73ae070358dbec8","nombre":"Cliente"}
      this.usuario = new Usuario(this.username,this.nombre,this.apellido,this.email,this.password,this.telefono,this.rol, this.empresa);
      this.usuarioService.guardarUsuario(this.usuario).subscribe((data) => {
        this._toastr.success('El usuario '+ data.nombre +' se ha guardado correctamente.','Correcto', {
          timeOut: 3000, progressAnimation: 'decreasing', closeButton: true});
          this.router.navigate(['/usuarios']);
      });
    } else {
      this._toastr.error('Revise los campos','Error');
    }
    
  }
  

}
