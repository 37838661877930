import { Component, OnInit } from '@angular/core';
import { list } from 'firebase/storage';
import { Toast, ToastrService } from 'ngx-toastr';
import { FilesService } from 'src/app/service/files.service';
import { TokenService } from 'src/app/service/token.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-listados',
  templateUrl: './listados.component.html',
  styleUrls: ['./listados.component.scss']
})
export class ListadosComponent implements OnInit {
  isAdmin = false;
  comprobacionTabla = false;
  listado:any[] =[];
  extension;
  fecha;
  constructor(private tokenService : TokenService, private filesService : FilesService, private _toastr : ToastrService) { }

  ngOnInit(): void {
    if (this.tokenService.getAuthorities() == "Administrador"){
      this.isAdmin = true;
    }
    this.filesService.get().subscribe((respuesta) => {
      this.listado = respuesta;
      if(this.listado.length == 0){
        this.comprobacionTabla = false
      } else {
        this.comprobacionTabla = true
      }
      var i = 0
      respuesta.forEach(element => { 
        this.extension = element.url.substring(element.url.lastIndexOf('.'),element.url.length);
        this.listado[i].extension = this.extension;

        var indice = element.created.indexOf("T")
        this.fecha = element.created.substring(0, indice);
        var fecha1 = new Date(this.fecha);
        var dia = fecha1.getDate()+1
        var mes = fecha1.getMonth()+1
        var anio = fecha1.getFullYear()
        var fechaCompleta = dia+"/"+mes+"/"+anio
        
        this.listado[i].fecha = fecha1;
        i++;
      });
    });
  }

  onClickDownloadPdf(data){
    this.filesService.listadosFileGet(data.url).then((url) => {
      this.downloadMyFile(url,data.nombre);
    })
    .catch((error) => {
    });
  
  }
  downloadMyFile(url, name){
    window.open(url, '_blank');
    var blob = new Blob([url], { type: 'application/pdf' });
    var url2 = window.URL.createObjectURL(blob);
    window.open(url2);
}

eliminarListado(data,i){
  Swal.fire({
    title: '¿Está seguro que  desea eliminar este elemento?',  
    icon: 'warning',
    cancelButtonColor: "#929292", 
    confirmButtonColor: "#DD6B55", 
    showCancelButton: true,
    confirmButtonText: 'Eliminar',  
    cancelButtonText: 'Cancelar'
  }).
  then((result) => {
    if (result.isConfirmed) {
      this.filesService.eliminarRegistro(data._id, data.url).then((url) => {
        this.listado.splice(i,1);
        this._toastr.success('Eliminado con exito', 'Correcto', {timeOut: 2000, progressBar: true, closeButton: true})       
    
      })
      .catch((error) => {
      });
        
       

      }
  })
}
}
