
export class Usuario {
    username: string;
    nombre: string;
    apellido: string;
    email:string;
    password: string;
    telefono: number;
    rol:Array<any>;
    empresa: string;
  constructor(
    username: string,
    nombre: string,
    apellido: string,
    email:string,
    password: string,
    telefono : number,
    rol: Array<any>,
    empresa: string
    ){
      this.username= username,this.nombre = nombre,this.apellido = apellido,this.email = email,this.password = password,this.telefono = telefono, this.rol = rol, this.empresa = empresa
      
    }


}
