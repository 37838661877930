import { Injectable } from '@angular/core';
import { initializeApp } from "firebase/app";
import { FileUpload } from '../models/file_upload';
import { environment } from 'src/environments/environment';
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';

import { getApp } from "firebase/app";
import { deleteObject, getDownloadURL, getStorage, listAll, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { Observable } from 'rxjs';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { HttpClient } from '@angular/common/http';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  private basePath = '/uploads';
  uploadProgress: Observable<number>
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  downloadURL: any;
  fullPath: string;
url2
  constructor(private afStorage: AngularFireStorage, private _http: HttpClient, private _url: UrlService
  ) {
    const app = initializeApp(environment.firebaseConfig);
    const db = getFirestore(app);
    const firebaseApp = getApp();
    // Upload file and metadata to the object 'images/mountains.jpg'

  }

  listadosFileUpload(file: File): Observable<any> {
    this.ref = this.afStorage.ref('listados/' + file.name);
    this.task = this.ref.put(file);
    //lo que se almacena en db para recuperar
    this.fullPath = 'listados/' + file.name;
    return this.uploadProgress = this.task.percentageChanges();
  }

  async listadosFileGet(fullPath : any) {
    const storage = getStorage();
    this.url2 = getDownloadURL(ref(storage, fullPath))
      .then((url) => {
        return url
      })
      .catch((error) => {
        return 
      });
    return this.url2
  }

  public guardarArchivoDB(data: FileUpload): Observable<any> {
    data.url = this.fullPath;
    return this._http.post(this._url.getURLBase() + '/listado/new_listado', data);
  }

  get(): Observable<any> {
    return this._http.get(this._url.getURLBase() + '/listado/');
  }

 async eliminarRegistro(listado_id :any, url) {
    const storage = getStorage();
    const desertRef = ref(storage, url);
    this.eliminar(listado_id).subscribe((url) => {
      return url;
    })
    deleteObject(desertRef).then(() => {
    }).catch((error) => {
      return error
    });
    return
  }

  eliminar(listado_id : any ): Observable<any> {
    return this._http.delete(this._url.getURLBase() + '/listado/' + listado_id);
  }
}
