import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
@Injectable({
  providedIn: 'root'
})
export class UrlService {
  private URL: string = "https://api.alvarezfiltros.com.ar";
  //private URL: string = "http://localhost:8001/";

  constructor(private httpClient: HttpClient) { }
  getURLBase(): string {
    return this.URL;
  }
}
