import { Component, OnInit, OnDestroy } from "@angular/core";
import Chart from "chart.js";
import { FormControl } from "@angular/forms";
import AOS from 'aos';
import { FormService } from "src/app/service/form.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-landingpage",
  templateUrl: "landingpage.component.html",
  styleUrls: ['./landingpage.components.scss']
})
export class LandingpageComponent implements OnInit, OnDestroy {

  focus;
  focus1;
  focus2;
  nombre: String;
  email: String;
  name: String; //asunto
  message: String;
  public inputControl: FormControl;

  slides = [
    {img: "assets/img/logo-banner/3.webp"},
    {img: "assets/img/logo-banner/1.webp"},
    {img: "assets/img/logo-banner/8.webp"},
    {img: "assets/img/logo-banner/4.webp"},
    {img: "assets/img/logo-banner/5.webp"},
    {img: "assets/img/logo-banner/6.webp"},
    {img: "assets/img/logo-banner/7.webp"},
    {img: "assets/img/logo-banner/2.webp"},
    {img: "assets/img/logo-banner/9.webp"},
    {img: "assets/img/logo-banner/10.webp"},
    {img: "assets/img/logo-banner/11.webp"},
    {img: "assets/img/logo-banner/12.webp"},
    {img: "assets/img/logo-banner/13.webp"},
    {img: "assets/img/logo-banner/14.webp"},
    {img: "assets/img/logo-banner/15.webp"},
    {img: "assets/img/logo-banner/16.webp"},
    {img: "assets/img/logo-banner/17.webp"}
  ];

  slideConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "centerMode": true,
    "focusOnSelect": true,
    "autoplay": true,
    "autoplaySpeed": 2000,
  };
 
  constructor(private formService:FormService) {}

  ngOnInit() {

    this.inputControl = new FormControl();
    window.onload = function(){


      var contenedor = document.getElementById('contenedor');
      contenedor.style.visibility = 'hidden';
      contenedor.style.opacity = '0';

      var wrapper = document.getElementById('wrapper');
      wrapper.style.display = 'block';

      var navbar = document.getElementById('navbar-top');
      navbar.style.display = 'block';

      AOS.init();
    
    }


    var body = document.getElementsByTagName("body")[0];
    body.classList.add("landing-page");


    var canvas: any = document.getElementById("chartBig");
    var ctx = canvas.getContext("2d");
    var gradientFill = ctx.createLinearGradient(0, 350, 0, 50);
    gradientFill.addColorStop(0, "rgba(228, 76, 196, 0.0)");
    gradientFill.addColorStop(1, "rgba(228, 76, 196, 0.14)");


    var chartBig = new Chart(ctx, {
      type: "line",
      responsive: true,
      data: {
        labels: [
          "JUN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC"
        ],
        datasets: [
          {
            label: "Data",
            fill: true,
            backgroundColor: gradientFill,
            borderColor: "#e44cc4",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#e44cc4",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#be55ed",
            //pointHoverBorderColor:'rgba(35,46,55,1)',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: [80, 160, 200, 160, 250, 280, 220, 190, 200, 250, 290, 320]
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false
        },

        tooltips: {
          backgroundColor: "#fff",
          titleFontColor: "#ccc",
          bodyFontColor: "#666",
          bodySpacing: 4,
          xPadding: 12,
          mode: "nearest",
          intersect: 0,
          position: "nearest"
        },
        responsive: true,
        scales: {
          yAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(0,0,0,0.0)",
                zeroLineColor: "transparent"
              },
              ticks: {
                display: false,
                suggestedMin: 0,
                suggestedMax: 350,
                padding: 20,
                fontColor: "#9a9a9a"
              }
            }
          ],

          xAxes: [
            {
              barPercentage: 1.6,
              gridLines: {
                drawBorder: false,
                color: "rgba(0,0,0,0)",
                zeroLineColor: "transparent"
              },
              ticks: {
                padding: 20,
                fontColor: "#9a9a9a"
              }
            }
          ]
        }
      }
    });

  }
  
  formSend(data){
    if (!data.valid) {

      this.formService.enviar(data).subscribe((resp) => {
        Swal.fire({
          title: 'Mensaje enviado correctamente',
          icon: 'success',
          text:'Nos contactaremos contigo a la brevedad',
          confirmButtonColor: "#F70C0C",
          confirmButtonText: 'Continuar',
        })
        this.nombre = "";
        this.email = "";
        this.message = "";
        this.name = "";
      });

    } else {
      alert("ERROR")
    }
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("landing-page");
  }
}
