// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://api.alvarezfiltros.com.ar',
  firebaseConfig : {
    apiKey: "AIzaSyCFUlxCXaczGPr5BpPGJcBL4JbX6WkWAwI",
    authDomain: "ar-distribuidora-92e36.firebaseapp.com",
    projectId: "ar-distribuidora-92e36",
    storageBucket: "ar-distribuidora-92e36.appspot.com",
    messagingSenderId: "372373028068",
    appId: "1:372373028068:web:f304d84c5a9660ba6c40f1"
  },
  bucket: "gs://ar-distribuidora-92e36.appspot.com "
};
