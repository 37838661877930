import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { RegisterpageComponent } from "./pages/examples/registerpage/registerpage.component";
import { LandingpageComponent } from "./pages/landingpage/landingpage.component";
import { LoginComponent } from "./pages/dashboard/login/login.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { ProdGuardService } from "./guards/prod-guard.service";
import { NovedadesComponent } from "./pages/dashboard/novedades/novedades.component";
import { NuevaNovedadComponent } from "./pages/dashboard/novedades/nueva-novedad/nueva-novedad.component";
import { UsuariosComponent } from "./pages/dashboard/usuarios/usuarios.component";
import { NuevoUsuarioComponent } from "./pages/dashboard/usuarios/nuevo-usuario/nuevo-usuario.component";
import { MensajesComponent } from "./pages/dashboard/mensajes/mensajes.component";
import { ListadosComponent } from "./pages/dashboard/listados/listados.component";
import { NuevoListadoComponent } from "./pages/dashboard/listados/nuevo-listado/nuevo-listado.component";

const routes: Routes = [
  { path: "", component: LandingpageComponent },
  //{ path: "home", component: IndexComponent },
  // { path: "profile", component: ProfilepageComponent },
  { path: "register", component: RegisterpageComponent },
  { path: "admin", component: LoginComponent },
  { path: "dashboard", component: DashboardComponent, canActivate: [ProdGuardService] },
  { path: "novedades", component: NovedadesComponent, canActivate: [ProdGuardService] },
  { path: "nueva-novedad", component: NuevaNovedadComponent, canActivate: [ProdGuardService] },
  { path: "usuarios", component: UsuariosComponent, canActivate: [ProdGuardService] },
  { path: "nuevo-usuario", component: NuevoUsuarioComponent, canActivate: [ProdGuardService] },
  { path: "mensajes", component: MensajesComponent, canActivate: [ProdGuardService] },
  { path: "listados", component: ListadosComponent },
  { path: "nuevo-listado", component: NuevoListadoComponent },
  { path: '**', redirectTo: "" }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: []
})
export class AppRoutingModule { }
