import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { FileUpload } from 'src/app/models/file_upload';
import { FilesService } from 'src/app/service/files.service';

import { TokenService } from 'src/app/service/token.service';


@Component({
  selector: 'app-nuevo-listado',
  templateUrl: './nuevo-listado.component.html',
  styleUrls: ['./nuevo-listado.component.scss']
})
export class NuevoListadoComponent implements OnInit {
  isAdmin = false;
  uploadProgress: Observable<number>;
  listado : FileUpload
  uploadFull: boolean = false;
  constructor(private tokenService : TokenService, private filesService: FilesService, private _toastr: ToastrService, private router: Router) {
  }

  ngOnInit(): void {
    if (this.tokenService.getAuthorities() == "Administrador"){
      this.isAdmin = true;
    }
  }

  upload(data){
    this.filesService.listadosFileUpload(data.target.files[0]).subscribe((respuesta) => {
      this.uploadProgress = respuesta;
      if (respuesta == 100){
        this.uploadFull = true;
      }
      });

  }

  public onSaveListado(data){
    this.listado = new FileUpload(data.nombre,"","","");
    this.filesService.guardarArchivoDB(this.listado).subscribe((respuesta) => {
      
      this._toastr.success('El PDF '+ data.nombre +' se ha guardado correctamente.','Correcto', {
        timeOut: 3000, progressAnimation: 'decreasing', closeButton: true});
      this.router.navigate(['/listados']);

      
      });
  }
}
