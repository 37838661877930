import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from './url.service';
import { CookieService } from 'ngx-cookie-service';  
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons';
const TOKEN_KEY = 'AuthToken';
const USERNAME_KEY = 'AuthUserName';
const AUTHORITIES_KEY = 'AuthAuthorities';
const ID_KEY = 'AuthId';
const NAME = 'AuthName';
const CI = 'CI';
import * as crypto from 'crypto-js'; 



@Injectable({
  providedIn: 'root'
})
export class TokenService {
  static getname(): string {
    throw new Error('Method not implemented.');
  }
  roles : Array<string> = [];

  constructor(private _http : HttpClient , private _url : UrlService, private cookieService: CookieService ) { }
  public setToken(token:string): void {
    this.cookieService.delete(TOKEN_KEY);
    
    this.cookieService.set(TOKEN_KEY,token, 2);
  }

  public getToken() : string {
    return this.cookieService.get(TOKEN_KEY);
  }

  public setUserName(userName:string): void {
    this.cookieService.delete(USERNAME_KEY);
    this.cookieService.set(USERNAME_KEY, userName, 2);
  }

  public setCI(value:string): void {
    this.cookieService.delete(CI);
    this.cookieService.set(CI, value);
  }
  public getCI(): string {
    return this.cookieService.get(CI);
  }
  public setId(id:string): void {
    this.cookieService.delete(ID_KEY);
    this.cookieService.set(ID_KEY, id, 2);
  }

  public setName(name:string): void{
    this.cookieService.set(NAME, name, 2);
  }
  
  public getId(): string {
    return this.cookieService.get(ID_KEY);
  }

  public getUserName() : string {
    return this.cookieService.get(USERNAME_KEY);
  }

  public getName(): string {
    return this.cookieService.get(NAME);
  }
   
  public setAuthorities(authorities:string): void {
    var ciphertext = crypto.AES.encrypt(authorities, 'The power is of DND').toString();
    this.cookieService.delete(AUTHORITIES_KEY);
    this.cookieService.set(AUTHORITIES_KEY, ciphertext, 2);
  }

  public getAuthorities(): string{
    var bytes  = crypto.AES.decrypt(this.cookieService.get(AUTHORITIES_KEY), 'The power is of DND');
    var originalText = bytes.toString(crypto.enc.Utf8);
    return originalText;
  }

  public  logOut(): void{
    this.cookieService.delete(USERNAME_KEY);
    this.cookieService.delete(AUTHORITIES_KEY);
    this.cookieService.delete(TOKEN_KEY);
    this.cookieService.delete(ID_KEY);
    this._http.post(this._url.getURLBase() + '/login/logout' ,this.getToken());
    
  }


}
