import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Novedad } from 'src/app/models/novedad';
import { NovedadService } from 'src/app/service/novedad.service';
import { TokenService } from 'src/app/service/token.service';

@Component({
  selector: 'app-nueva-novedad',
  templateUrl: './nueva-novedad.component.html',
  styleUrls: ['./nueva-novedad.component.scss']
})
export class NuevaNovedadComponent implements OnInit {
  base64pdfString = [];
  novedad: Novedad;
  isPermision = false;
  roles: string;
  uploadProgress: Observable<number>;

  constructor(private NovedadService : NovedadService,
    private router: Router,
    private _toastr: ToastrService, 
    private tokenService: TokenService) { }

  ngOnInit(): void {
    this.roles = this.tokenService.getAuthorities();

    if (this.roles == 'Administrador' || this.roles == 'SuperAdministrador') {
      this.isPermision = true;

    } else {
      this.isPermision = false;
    }
  }

  //#region Convert PDF to Base64
  onUploadChange(evt: any) {
    const file = evt.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  handleReaderLoaded(e) {
    //'data:application/pdf,' +
    this.base64pdfString.push( btoa(e.target.result));
  }

  onSaveNovedad(data){
    data.pdf = this.base64pdfString;
    this.novedad = new Novedad(data.nombre,data.pdf);
    this.NovedadService.guardarNovedad(this.novedad).subscribe((respuesta) => {
      this._toastr.success('El PDF '+ data.nombre +' se ha guardado correctamente.','Correcto', {
        timeOut: 3000, progressAnimation: 'decreasing', closeButton: true});
      this.router.navigate(['/novedades']);
      });
  }
}
